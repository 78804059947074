<template>
  <div>
    <div style="position: relative;margin-bottom: 23px;">
      <div
        style="position: absolute;top:150px;left:72px;z-index: 999;text-align: center;height: 87px;line-height: 67px;display: flex;">

        <el-image v-if="this.totalData.companyLogo" :src="img + this.totalData.companyLogo" fit="cover"
          style="width:72px;height: 72px;margin-right: 20px; ">

        </el-image>

        <!-- <div class="companyname">{{ this.totalData.sysTitle }}</div> -->
       
  <div class="companyname">{{'高邮大虾养殖溯源管理系统'}}</div>
      </div>
      <div  v-show="false" @click="opennew" style="position: absolute;bottom:42px;right:72px;z-index: 999;text-align: center;height:50px;display: flex;width: 185px;height: 50px;background: #FFFFFF;border-radius: 30px;">

        <el-image :src="sp" fit="cover" style="width:33px;height: 41px;margin-right: 12px;margin-left: 20px; ">

        </el-image>

        <div style="font-weight: 600;
color: #6DCE84;
line-height: 50px;height: 50px;">牧场实时监控</div>
      </div>
      <div style="position: absolute;top:38px;right:36px;z-index: 999;text-align: center;width: 168px;
height: 34px;
border-radius: 4px;">

        <div @click="sc" style="font-weight: 600;
color: #FFFFFF;
background: #6DCE84;
line-height: 34px;">市场监督管理局登录</div>
        <div @click="nm" style="font-weight: 600;
        margin-top: 11px;
color: #FFFFFF;
background: #6DCE84;
line-height: 34px;">
          农业局登录
        </div>
      </div>
      <el-image :src="src" style="width:100% ;height:400px" fit="cover">

      </el-image>

    </div>

   <div @click="scrollClick($event)">
    <vue-seamless-scroll :data="listData" :class-option="classOption" class="warp" v-if="listData.length!==0">
      <ul class="ul-item">
        <li class="li-item" v-for="(item, index) in listData" :key="index"  >

          <!-- <el-image      :src="img+item.fid"    > -->
          <!-- </el-image> -->
          <img :src="img + item.fid" alt=""  :id="item.contractId" :name="item.companyId"  :data-dept="item.contractStatus"/>


        </li>
      </ul>
    </vue-seamless-scroll>

   </div>


    <div class="test">
      <ul>
        <li>
          <div class="big">
            <div class="tip">
              {{ '合同数量' }}
              <div>
                <span class="number">{{ totalData.contractNum }}</span>
                <span class="tiao">个</span>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div class="big">
            <div class="tip">
              {{ '进行中合同' }}
              <div>
                <span class="number">{{ totalData.processNum }}</span>
                <span class="tiao">个</span>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div class="big">
            <div class="tip">
              {{ '已完成合同' }}
              <div>
                <span class="number">{{ totalData.overNum }}</span>
                <span class="tiao">个</span>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div class="big">
            <div class="tip">
              {{ '生长记录' }}
              <div>
                <span class="number">{{ totalData.growNum }}</span>
                <span class="tiao">次</span>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div class="big">
            <div class="tip">
              {{ '销售记录' }}
              <div>
                <span class="number">{{ totalData.saleNum }}</span>
                <span class="tiao">次</span>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div class="big">
            <div class="tip">
              {{ '员工人数' }}
              <div>
                <span class="number">{{ totalData.empNum }}</span>
                <span class="tiao">人</span>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <!-- 底部信息 -->
    <div class="mes">
      <div>
        <span>主办单位:</span>
        <span>{{ totalData.companyName }}</span>
      </div>
      <div style="margin-left:30px">
        <span>地址:</span>
        <span>{{ totalData.address }}</span>
      </div>
    </div>
    <div class="mes" style="margin-top:16px">
      <div>
        <span>联系人:</span>
        <span>{{ totalData.contactName }}</span>
      </div>
      <div style="margin-left:30px">
        <span>联系电话:</span>
        <span>{{ totalData.tel }}</span>
      </div>

    </div>
    <div class="mes" style="margin-top:16px">
      <div>
        苏ICP备2022041888号
      </div>


    </div>
  </div>
</template>

<script>
import { getmsg, getswiper } from '../../request/http'
import vueSeamlessScroll from 'vue-seamless-scroll'
export default {
  name: 'Example01Basic',
  components: {
    vueSeamlessScroll
  },
  data() {
    return {
      listData: [],
      classOption: {
        limitMoveNum: 3,
        direction: 3,
        hoverStop: false
      },
      totalData: [],//统计信息
      // src: require('../../assets/beijing.png'),
      src: require('../../assets/bj.png'),
      sp: require('../../assets/icon_shexiangtou.png')
    }
  },
  created() {
    this.getmsg()
    this.getswiper()
  },
  methods: {
    scrollClick(event) {
      
      let  contractId   = event.target.id
     let companyId   = event.target.name
    let  status  = event.target.dataset.dept
     console.log(contractId,companyId);
     if(contractId){

      if(status==100||status==201||status==203){
    //待审核
       
   
   
      this.$router.push({
        path: "/contractReview",
        query: { contractId },
      });
   }
   else if(status==202||status==300||status==400){
    this.$router.push({
        path: "/contractInfo",
        query: { companyId,contractId },
      });
   }
     }
     else {
     console.log(false);
     }
     

    
    },
    gotodetail(val){
    console.log(val);
    
    if(val.contractId&&val.companyId){
      let contractId = val.contractId;
    let companyId =val.companyId
      this.$router.push({
        path: "/contractInfo",
        query: { companyId,contractId },
      });
    }
  
  },

    sc() {
      window.open(
        "http://farm.uutele.com/",
        "_blank"
      );
    },

    nm() {
      window.open(
        "http://farm.uutele.com/",
        "_blank"
      );
    },
    opennew() {
      window.open(
        "https://27.nongyedengta.com/pages/live/list",
        "_blank"
      );
    },
    async getswiper() {
      const { data } = await getswiper()
      console.log(data);
      this.listData = data.data

    },
    async getmsg() {
      const { data } = await getmsg()
      console.log(data);
      this.totalData = data.data
    }
  }
}
</script>

<style   lang="scss" scoped>
.companylog {
  width: 72px;
  height: 72px;

}

.companyname {
  height: 67px;
  font-size: 48px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 67px;
  letter-spacing: 1px;
  text-shadow: 7px 6px 6px rgba(6,0,1,0.66);
background: linear-gradient(0deg, #EAFFFD 0%, #68FFEF 100%);
-webkit-background-clip: text;
}

.mes {
  display: flex;
  align-items: center;
  justify-content: center;
}

.big {
  display: inline-block;
  width: 371px;
  height: 200px;
  text-align: center;
  margin: 0 auto;
}

ul {
  text-decoration: none;
}

.test ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  text-decoration: none;
  text-align: center;
  margin: 0 30px;
}

.test ul li {
  margin: 30px auto;
  height: 200px;
  text-align: center;

  border-radius: 8px;
  list-style: none;
  width: 371px;
}

li:nth-child(1) {
  background: #f5fbff;
}

li:nth-child(2) {
  background: #f9f5ff;
}

li:nth-child(3) {
  background: #fff3f3;
}

li:nth-child(4) {
  background: #f5fffc;
}

li:nth-child(5) {
  background: #fffcf5;
}

li:nth-child(6) {
  background: rgba(126, 211, 33, 0.05);
}

.test ul:after {
  content: '';
  height: 0;
  width: 32%;
}

.tip {
  text-align: center;
  margin: 57px 130px 115px 130px;
  width: 120px;
  height: 28px;
  font-size: 20px;
  font-family: PingFang-SC-Regular, PingFang-SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
}

.number {
  display: inline-block;
  margin: 10px 10px 0 8px;
  width: 18px;
  height: 25px;
  font-size: 18px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
  line-height: 25px;
}

.tiao {
  display: inline-block;
  margin-left: 8px;
  width: 18px;
  height: 25px;
  font-size: 18px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
  line-height: 25px;
}

.bj {
  width: 100% !important;
  height: 380px;
  background: url('../../assets/beijing.png') no-repeat center !important;
}

.minecard {
  box-sizing: border-box;
  width: 320px;
  height: 115px !important;
  text-align: center;
}

.minecard_item_title {
  color: #909399;
  font-size: 20px;
  line-height: 20px;
  font-weight: 700;
}

.minecard_item_text {
  padding-top: 10px;
  color: #303133;
  font-size: 33px;
  line-height: 1;
  font-weight: 700;
}

.minecard .el-card__body {
  box-sizing: border-box;
  height: 100%;
}

.warp {
  width: 404px * 4;
  height: 228px;
  margin: 0 auto;
  overflow: hidden;

  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;

    &.ul-item {
      display: flex;

      .li-item {
        width: 404px;
        height: 228px;
        margin-right: 10px;
        line-height: 120px;



        border-radius: 8px;
        text-align: center;
        font-size: 30px;

        img {
          width: 404px;
          height: 228px;
          margin-right: 10px;
          line-height: 120px;



          border-radius: 8px;
          text-align: center;
          font-size: 30px
        }
      }
    }
  }
}
</style>

